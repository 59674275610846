import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { device } from "../utils/mixins"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { PageTopFullSizeImgCont } from "../components/imageComponents"
import { PageSectionTitle, I2BronzeText } from "../components/textComponents"
import { PageImageButton } from "../components/interactiveComponents"

var Image = styled(Img)`
  max-height: 15rem;
  position: absolute;

  ${device.mobileX} {
    max-height: 20rem;
  }

  ${device.tablet} {
    max-height: 23rem;
  }
`

function FAQs() {
  var data = useStaticQuery(graphql`
    query {
      faqsTopImg: file(
        relativePath: {
          eq: "FAQS_I2_Optimization_Oil_And_Gas_North_America.jpeg"
        }
      ) {
        ...fluidImageSVG
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Frequently Asked Questions"
        description="Zipper frac or Monoline? Plug and Perf or Ball and Seat? We give you the answers you're looking for, backed up by industry data."
      />
      <PageTopFullSizeImgCont>
        <Image
          style={{ height: "inherit" }}
          objectFit="cover"
          fluid={data.faqsTopImg.sharp.fluid}
          alt="i2 Optimization - Fracking Solutions, Bi-Fuel Substitution, Strategic Planning"
        />
        <PageSectionTitle>
          <I2BronzeText>FAQs</I2BronzeText>
        </PageSectionTitle>
        <PageImageButton to="/contact-us/">Contact Us</PageImageButton>
      </PageTopFullSizeImgCont>
    </Layout>
  )
}

export default FAQs
